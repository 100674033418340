<template>
  <div id="system">系统</div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {};
  },
  mounted() {
    util.post(
      "/sysResPool",
      { file_type: this.$route.query.filetype },
      (res) => {
        console.log(res);
      }
    );
  },
};
</script>
<style lang="less" scoped>
#system {
  width: 100%;
  height: 100%;
}
</style>